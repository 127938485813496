<script lang="ts" setup>
  const mapEl = ref();

  const navigationStore = useNavigationStore();

  useIntersectionObserver(
    mapEl,
    ([{ isIntersecting }]) => {
      const tab = navigationStore.navigationTabs.find((t) => t.link === 'map');
      if (tab) {
        tab.isActive = isIntersecting;
      }
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div>
    <MainPageMapWithToggle ref="mapEl" title-type="doctors" />
  </div>
</template>
